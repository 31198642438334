import { graphql } from 'gatsby';
import { Location } from 'history';
import React from 'react';
import SummaryBaseComponent, { SummaryData } from 'components/FormPage/summary';
import { DriverType } from 'types/forms';

type SummaryProps = {
  data: {
    csAddDriverSummaryTemplate: SummaryData;
  };
  location: Location;
};

const SummaryPage: React.FC<SummaryProps> = ({ data, location }) => (
  <SummaryBaseComponent
    data={data.csAddDriverSummaryTemplate}
    location={location}
    driverType={DriverType.TEMPORARY}
  />
);

export default SummaryPage;

export const query = graphql`
  query {
    csAddDriverSummaryTemplate(driver_type: { eq: "Temporary" }) {
      meta_title
      heading
      subheading
    }
  }
`;
